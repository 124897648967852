import Axios from "@/plugins/Axios";
// import store from "@/store";
import GestaoDeAula from "@/Models/GestaoDeAula";
import Aula from "@/Models/Aula";

export class GestoesDeAulasComplementarService {
  async syncAll(pageNumber, query) {
    const response = await Axios().get(
      `gestoes-de-aulas-complementar?page=${pageNumber}&query=${query}`
    );

    // store.commit("GestoesDeAulas/setGestoesDeAulas", gestoesDeAulas);
    return response.data;
  }

  async listarAulasGestao(gestaoDeAulaId) {
    const response = await Axios().post("gestoes-de-aulas-complementar/listar-aulas-gestao", {
      gestao_id: gestaoDeAulaId,
    });
    const aulas = response.data.gestaoDeAula.aulas_complementares.map((aulas) => new Aula(aulas));
    const payload = response.data;
    return { aulas, payload };
  }

  async listarAulasIntervaloData(gestaoDeAulaId, intervalo) {
    const response = await Axios().post(
      "gestoes-de-aulas-complementar/listar-aulas-intervalo-datas",
      {
        gestao_id: gestaoDeAulaId,
        intervalo,
      }
    );
    const aulas = response.data.gestaoDeAula.aulas_complementares.map((aulas) => new Aula(aulas));
    const payload = response.data;
    return { aulas, payload };
  }

  async listarAulasDaGestaoPaginate(gestaoDeAulaId, pageNumber = 0, query = "") {
    const response = await Axios().post(
      `gestoes-de-aulas-complementar/listar-aulas-da-gestao-paginate?page=${pageNumber}&query=${query}`,
      {
        gestao_id: gestaoDeAulaId,
      }
    );
    const aulas = response.data.aulas.data.map((aulas) => new Aula(aulas));
    const data = response.data.aulas;
    const payload = response.data;
    return { aulas, payload, data };
  }

  async listarAulasGestaoInfantil(gestaoDeAulaId) {
    const response = await Axios().post(
      "gestoes-de-aulas-complementar/listar-aulas-gestao-infantil",
      {
        gestao_id: gestaoDeAulaId,
      }
    );
    const aulas = response.data.gestaoDeAula.aulas.map((aulas) => new Aula(aulas));
    const payload = response.data;
    return { aulas, payload };
  }

  async visualizar(gestaoDeAula) {
    const response = await Axios().get(`gestoes-de-aulas-complementar/${gestaoDeAula}/visualizar`);
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async visualizarTurma(gestaoDeAula) {
    const response = await Axios().get(
      `gestoes-de-aulas-complementar/${gestaoDeAula}/visualizar/turma`
    );
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async gestaoGeralId(gestaoDeAulaId) {
    const filtro = true;
    const response = await Axios().get(
      `gestoes-de-aulas-complementar/${gestaoDeAulaId}/visualizar/${filtro}`
    );
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async geral() {
    const response = await Axios().get(`gestoes-de-aulas-complementar/geral`);
    const gestoesDeAulasGeral = response.data.map(
      (gestaoDeAulas) => new GestaoDeAula(gestaoDeAulas)
    );
    // store.commit("GestoesDeAulas/setGestoesDeAulas", gestoesDeAulasGeral);

    return gestoesDeAulasGeral;
  }

  async geralPaginate(pageNumber, query) {
    const response = await Axios().get(
      `gestoes-de-aulas-complementar/geral?page=${pageNumber}&query=${query}`
    );

    return response.data;
  }

  async listarGestoesProfessor(gestao) {
    const response = await Axios().post(`gestoes-de-aulas-complementar/listar-gestoes-professor`, {
      gestao,
    });
    return response.data;
  }

  async listarGestaoDeAulasDaTurma(turma_id) {
    const response = await Axios().post(
      `gestoes-de-aulas-complementar/listar-gestoes-aulas-turma`,
      {
        turma_id,
      }
    );
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async removeAlunoById(id, gestaoId) {
    const response = await Axios().delete(`gestoes-de-aulas-complementar/aluno/${id}/${gestaoId}`);
    return response.data;
  }

  async criar(relacoesList) {
    const response = await Axios().post("gestoes-de-aulas-complementar", relacoesList);
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async atualizar(gestaoDeAulas) {
    const response = await Axios().put(
      `gestoes-de-aulas-complementar/${gestaoDeAulas.id}`,
      gestaoDeAulas
    );
    const gestaoDeAulasAtualizada = response.data;
    // store.commit("GestoesDeAulas/atualizarGestaoDeAula", gestaoDeAulasAtualizada);
    return gestaoDeAulasAtualizada;
  }

  async deletarGestaoDeAula(gestaoDeAulas) {
    const response = await Axios().delete(`gestoes-de-aulas-complementar/${gestaoDeAulas.id}`);
    const gestaoDeAulasDeletada = response.data;
    // store.commit("GestoesDeAulas/deletarGestaoDeAula", gestaoDeAulas.id);
    return gestaoDeAulasDeletada;
  }

  async recuperarGestaoDeAula(gestaoDeAulas) {
    return this.atualizar({ ...gestaoDeAulas, deleted_at: null });
  }

  async historicoAulasLancadas(gestaoDeAulaId) {
    const response = await Axios().get(
      `gestoes-de-aulas-complementar/historico-aulas-lancadas/${gestaoDeAulaId}`
    );
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async historicoPlanosLancados(gestaoDeAulaId, mes_id, disciplina_id) {
    const response = await Axios().get(
      `gestoes-de-aulas-complementar/historico-planos-lancados/${gestaoDeAulaId}/${mes_id}/${disciplina_id}`
    );
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }
}
export default new GestoesDeAulasComplementarService();
