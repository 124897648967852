<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'aulas.complementares',
                  params: { gestaoDeAulaId: $route.params.gestaoDeAulaId },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aula
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green" class="mb-5 d-flex" outlined>
          <v-card-text>
            <template v-if="!gestaoDeAula.is_polivalencia">
              <h4>Disciplina: {{ gestaoDeAula.disciplina?.descricao }}</h4>
            </template>
            <template v-else>
              <h4>Disciplinas:</h4>
              <div class="container">
                <div class="row">
                  <div v-for="(item, index) in gestaoDeAula.disciplinas" :key="index">
                    <v-chip class="ml-2" x-small>{{ item.descricao }} </v-chip>
                  </div>
                </div>
              </div>
            </template>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <div>
          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <ValidationProvider name="Tipo de Aula" rules="required" v-slot="{ errors }">
                <e-label>Tipo de Aula</e-label>
                <e-autocomplete
                  :items="$constants.tiposDeAulas"
                  :return-object="false"
                  :error-messages="errors"
                  v-model="aulaSelecionada.tipo_de_aula"
                  @change="() => verificaTipoDaAula()"
                  label="Selecione um tipo de aula"
                  dense
                  solo
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row v-if="mostrarCampos">
            <v-col class="pt-0 pb-0" cols="6">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="data"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <e-label>Selecione uma data</e-label>
                  <v-text-field
                    v-model="aulaSelecionada.data"
                    persistent-hint
                    :disabled="aulaSelecionada.tipo_de_aula ? false : true"
                    label="Escolha uma data"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data"
                  scrollable
                  :min="inicioPeriodoEtapa"
                  :max="fimPeriodoEtapa"
                  :allowed-dates="allowedDates"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(data)"> OK </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col class="pt-0 pb-0" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'" cols="6">
              <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
                <e-label>Selecione um horário</e-label>

                <e-autocomplete
                  :disabled="aulaSelecionada.data ? false : true"
                  :items="horarios"
                  :error-messages="errors"
                  label="Selecione uma opção"
                  :item-text="(item) => item.descricao"
                  v-model="horarioSelecionado"
                  return-object
                  dense
                  @change="() => pesquisaAulas()"
                />
              </ValidationProvider>
              <!-- @change="() => pesquisaAulas()" -->
            </v-col>

            <v-col
              cols="12"
              v-if="horarioSelecionado || aulaSelecionada.tipo_de_aula === 'Aula Remota' || editing"
            >
              <template v-if="outrosCampos && !gestaoDeAula.is_polivalencia">
                <e-label>Conteúdo</e-label>
                <v-textarea solo dense v-model="aulaSelecionada.conteudo" />
              </template>

              <ValidationProvider
                v-if="outrosCampos"
                name="Metodologia"
                rules="required"
                v-slot="{ errors }"
              >
                <e-label>Metodologia</e-label>
                <v-textarea
                  solo
                  dense
                  v-model="aulaSelecionada.metodologia"
                  :error-messages="errors"
                />
              </ValidationProvider>

              <!-- <div v-if="outrosCampos">
                <e-label>Objetivos</e-label>
                <v-textarea solo dense v-model="aulaSelecionada.objetivos_ou_habilidades" />
              </div>

              <div v-if="outrosCampos">
                <e-label>Avaliação</e-label>
                <v-textarea solo dense v-model="aulaSelecionada.avaliacao" />
              </div>

              <div v-if="outrosCampos">
                <e-label>Atividade</e-label>
                <v-textarea solo dense v-model="aulaSelecionada.atividade_classe" />
              </div>

              <div v-if="outrosCampos">
                <e-label>Recursos</e-label>
                <v-textarea solo dense v-model="aulaSelecionada.recursos" />
              </div> -->

              <v-btn
                v-if="outrosCampos"
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn
                v-if="editing && outrosCampos"
                class="ml-3"
                color="success"
                @click="dialog.duplicar = true"
                >Duplicar Aula</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <v-row v-if="etapas?.length === 0 && etapaBloqueada == false">
          <v-col cols="12">
            <v-alert type="warning" border="left">
              Não existem etapas disponíveis para a data atual
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="etapaBloqueada == true && etapaPendente == false">
          <v-col cols="12">
            <v-alert :class="avisoAnime" type="warning" border="left">
              Atenção essa etapa está bloqueada, para lançar aulas você deve solicitar uma
              autorização, clicando no botão acima
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="etapaPendente == true">
          <v-col cols="12">
            <v-alert :class="avisoAnime" type="warning" border="left">
              Aguarde, já existe um pedido de autorização para essa etapa e estamos analisando.
            </v-alert>
            <v-btn class="success" @click="verPedidos">Acompanhe seus pedidos</v-btn>
          </v-col>
        </v-row>

        <div v-if="etapas?.length > 0 && etapaBloqueada == false && etapaSelecionada">
          <v-row
            v-if="!aulaSelecionada.tipo_de_aula || !aulaSelecionada.data || !horarioSelecionado"
          >
            <v-col cols="12" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'">
              <v-alert type="warning" border="left"> Por favor, preencha todos os campos! </v-alert>
            </v-col>
          </v-row>
        </div>
      </form>

      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <h2 class="mt-4">Aulas conflitadas</h2>

          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Disciplina</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor.nome }}
                  </td>
                  <td>{{ item.situacao ? item.situacao : "Aguardando" }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.disciplina?.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </ValidationObserver>
    <e-modal-duplicar-aula-complementar
      v-if="aulaSelecionada.disciplinas"
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      :disciplinas="aulaSelecionada.disciplinas"
      :tipoAula="'complementar'"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula-complementar>
    <e-modal-duplicar-aula-complementar
      v-else
      :tipoAula="'complementar'"
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula-complementar>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";
import { dateFormatParam } from "../../plugins/filters";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
  },
  computed: {
    tipo_de_aula() {
      return this.aulaSelecionada.tipo_de_aula;
    },
  },
  mounted() {
    this.loadData();
    if (this.editing) {
      this.mostrarCampos = true;
      this.outrosCampos = true;
    }
  },
  data() {
    return {
      diasPermitidosParaCriarAula: [0, 1, 2, 3, 4, 5, 6],
      conteudos: [],
      arrayConteudos: [],
      text: "",
      textColor: "#000000",
      disciplinasMarcadas: [],
      avisoAnime: "",
      etapaPendente: false,
      etapaBloqueada: false,
      idsEtapasDisponivels: [],
      idsEtapasPendentes: [],
      autorizacao: [],
      mostrarEtapas: false,
      fimPeriodoEtapa: null,
      inicioPeriodoEtapa: null,
      etapaSelecionada: null,
      etapas: null,
      turma: null,
      data: null,
      modal: false,
      aulasEmConflitos: false,
      aulasConflitadas: [],
      mostrarCampos: false,
      outrosCampos: false,
      submittingForm: false,
      form: {},
      aulaSelecionada: {
        data: null,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      aulaDuplicada: {},
      gestaoDeAulas: null,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      horarios: [],
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
      arrayConteudoEditar: [],
    };
  },
  watch: {
    horarioSelecionado() {
      console.log("chamou");
      this.pesquisaAulas();
    },
    tipo_de_aula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota") {
        this.aulaSelecionada.horario_inicial = null;
        this.aulaSelecionada.horario_final = null;
        this.horarioSelecionado = null;
      }
    },
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(value));
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
      this.pesquisaAulas();
    },
  },
  methods: {
    getNomeDisciplina(id) {
      const dis = this.gestaoDeAula.disciplinas.filter((obj) => obj.id === id);
      return dis[0].descricao;
    },
    editarConteudoPoli(index, $event) {
      this.arrayConteudos[index] = $event;
    },

    updateText(event) {
      this.text = event.target.innerHTML;
    },
    executeCommand(command) {
      document.execCommand(command, false, null);
    },

    changeFont(size) {
      document.execCommand("fontSize", false, "7");
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const node = document.createElement("span");
        node.style.fontSize = size;
        range.surroundContents(node);
      }
    },
    changeStyle(style) {
      document.execCommand(style, false, null);
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const node = document.createElement("span");
        node.style.fontStyle = style === "italic" ? style : null;
        node.style.fontWeight = style === "bold" ? style : null;
        node.style.textDecoration = style === "underline" ? style : null;
        range.surroundContents(node);
      }
    },
    setTextColor() {
      this.executeCommand("foreColor", this.textColor);
    },
    animarAviso() {
      this.avisoAnime = "animate__animated animate__headShake";
      setTimeout(() => (this.avisoAnime = ""), 500);
    },
    dateFormatConvert(data) {
      return dateFormatParam(data);
    },
    verificarEtapaSelecionada() {
      this.animarAviso();
      this.inicioPeriodoEtapa = this.etapas
        .filter((d) => d.id === this.etapaSelecionada)
        .shift().periodo_inicial;
      this.inicioPeriodoEtapa = dateFormatParam(this.inicioPeriodoEtapa, "y-m-d");
      this.fimPeriodoEtapa = this.etapas
        .filter((d) => d.id === this.etapaSelecionada)
        .shift().periodo_final;
      this.fimPeriodoEtapa = dateFormatParam(this.fimPeriodoEtapa, "y-m-d");

      const etapaVerificar = this.etapas.filter((d) => d.id === this.etapaSelecionada).shift();

      this.verificarSeEtapaEstaDisp(etapaVerificar);
    },
    async pesquisaAulas() {
      const data_aula = this.aulaSelecionada.data.split("/").reverse().join("-");
      // /* eslint-disable */
      const { aula_id } = this.$route.params;
      const horario_id = this.horarioSelecionado.id;
      if (!data_aula || !this.horarioSelecionado || !this.aulaSelecionada) return;

      const response = await this.$services.aulasComplementaresService.pesquisarAulasEmConflitos(
        data_aula,
        horario_id,
        this.$route.params.gestaoDeAulaId,
        aula_id
      );

      if (response.data?.length === 0) {
        this.mostrarCampos = true;
        this.outrosCampos = true;
      } else {
        // console.log(response.data);
        this.aulasConflitadas = response.data;
        this.aulasEmConflitos = true;
        this.outrosCampos = false;
      }
    },
    async verificaTipoDaAula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota" || this.editing) {
        this.getAula();
        this.mostrarCampos = true;
        this.outrosCampos = true;
        return;
      }
      await this.getHorarios();
      this.mostrarCampos = true;
      this.outrosCampos = false;
    },
    async submitForm() {
      this.aulaSelecionada.horario_inicial = this.horarioSelecionado
        ? this.horarioSelecionado.inicio
        : null;
      this.aulaSelecionada.horario_final = this.horarioSelecionado
        ? this.horarioSelecionado.final
        : null;
      this.aulaSelecionada.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;

      this.submittingForm = true;
      try {
        if (this.editing) {
          this.aulaSelecionada.gestao_complementar_id = parseInt(
            this.$route.params.gestaoDeAulaId,
            10
          );
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
          await this.$services.aulasComplementaresService.atualizar(this.aulaSelecionada);
          this.$toast.success("Aula editada com sucesso!");
          this.$router.back();
        } else {
          this.aulaSelecionada.gestao_complementar_id = parseInt(
            this.$route.params.gestaoDeAulaId,
            10
          );
          this.aulaSelecionada.disciplina_id = this.gestaoDeAula.disciplina_id;
          this.aulaSelecionada.instrutor_id = parseInt(this.gestaoDeAula.instrutor_id, 10);
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
          await this.$services.aulasComplementaresService.criar(this.aulaSelecionada);
          this.$toast.success("Aula cadastrada com sucesso!!");
          this.$router.back();
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async getAula() {
      await this.getHorarios();
      const { aula_id } = this.$route.params;
      const response = await this.$services.gestoesDeAulasComplementarService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.gestaoDeAula = response.payload.gestaoDeAula;
      this.horarios = response.payload.horarios.filter((horario) => {
        return horario.descricao;
      });
      const aulaSelecionada = await this.$services.aulasComplementaresService.getAula(
        parseInt(aula_id, 10)
      );
      this.horarioSelecionado = aulaSelecionada.horario ?? null;

      this.text = aulaSelecionada.conteudo;

      if (this.gestaoDeAula.relacoesDiasHorarios) {
        this.diasPermitidosParaCriarAula = this.gestaoDeAula.relacoesDiasHorarios.map(
          (g) => g.dia.id
        );

        this.horarios = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.horario);
        this.horariosSabado = this.horarios;
        this.horariosSabado = this.horariosSabado.map((h) => {
          return {
            id: h.id,
            turno_id: h.turno_id,
            descricao: h.descricao.split(" - ")[0],
            final: h.final,
            inicio: h.inicio,
          };
        });
        this.horariosDoComponente =
          new Date(this.data).getDay() === 5 ? this.horariosSabado : this.horarios;
      }

      return aulaSelecionada;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.getHorarios();
        this.aulaSelecionada = await this.getAula();

        if (this.aulaSelecionada.data) {
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async getHorarios() {
      const response = await this.$services.gestoesDeAulasComplementarService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.gestaoDeAula = response.payload.gestaoDeAula;

      if (this.gestaoDeAula.relacoesDiasHorarios) {
        this.diasPermitidosParaCriarAula = this.gestaoDeAula.relacoesDiasHorarios.map(
          (g) => g.dia.id
        );

        this.horarios = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.horario);
        this.horariosSabado = this.horarios;
        this.horariosSabado = this.horariosSabado.map((h) => {
          return {
            id: h.id,
            turno_id: h.turno_id,
            descricao: h.descricao.split(" - ")[0],
            final: h.final,
            inicio: h.inicio,
          };
        });
      }
      this.horariosDoComponente =
        new Date(this.data).getDay() === 5 ? this.horariosSabado : this.horarios;

      // this.horarios = response.payload.horarios.filter((horario) => {
      //   return horario.descricao.toLowerCase().includes("momento");
      // });
    },

    allowedDates(val) {
      const array = [...this.diasPermitidosParaCriarAula];
      return array.includes(new Date(val).getDay());
    },
    getEtapa() {
      let etapa = this.etapas.filter((d) => d.id === this.aulaSelecionada.etapa_id);
      etapa = etapa.shift();
      this.fimPeriodoEtapa = etapa.periodo_final;
      this.inicioPeriodoEtapa = etapa.periodo_inicial;
      this.etapaSelecionada = etapa;
    },
    criarPedido(etapaId) {
      this.$router.push({ name: "autorizacoes.create", params: { etapa: etapaId } });
    },
    verPedidos() {
      this.$router.push({ name: "autorizacoes" });
    },
    verificarSeEtapaEstaDisp(etapa) {
      if (this.idsEtapasDisponivels.includes(etapa.id)) {
        this.etapaBloqueada = false;
      } else {
        this.etapaBloqueada = true;
      }
      if (this.idsEtapasPendentes.includes(etapa.id)) {
        this.etapaPendente = true;
      } else {
        this.etapaPendente = false;
      }
    },
    async verificarSeTemPedido(etapaId) {
      const response = await this.$services.autorizacoesService.verificarPedidosDeferidos(etapaId);

      if (response.data.aprovados?.length) {
        this.idsEtapasDisponivels.push(etapaId);
      }

      if (response.data.pendentes?.length) {
        this.idsEtapasPendentes.push(etapaId);
      }
    },
    beforeDestroy() {
      this.text = this.$refs.editor.innerHTML;
    },
  },
};
</script>

<style scoped>
.dataInput {
  background-color: white;
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
</style>
