<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="primary">
          <span style="color: #fff" class="text-h5">Duplicar Aula - Polivalência</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(enableSubmit)" :disabled="false">
                <v-row>
                  <v-col class="pt-4 pb-0" cols="12">
                    <e-label class="font-weight-medium">Tipo de Aula</e-label>
                    <p class="font-weight-regular">
                      {{ aulaDuplicada.tipo_de_aula }}
                    </p>
                  </v-col>

                  <v-col class="pt-0 pb-0" :cols="12" :sm="4">
                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <e-label>Selecione uma data</e-label>
                        <v-text-field
                          dense
                          solo
                          v-model="data"
                          label="data"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        v-model="dataAulaDuplicada"
                        :allowed-dates="allowedDates"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(date)"> OK </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-col
                    class="pt-0 pb-0"
                    v-if="aulaDuplicada.tipo_de_aula !== 'Aula Remota'"
                    cols="6"
                  >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-alert
                      v-show="messageAlert"
                      class="clickable"
                      border="left"
                      elevation="3"
                      type="warning"
                      >{{ messageAlert }}</v-alert
                    >
                    <e-label class="font-weight-medium mb-5">Conteúdo</e-label>
                    <div
                      v-for="(item, index) in JSON.parse(aulaDuplicada.horarios_extras)"
                      :key="index"
                    >
                      <li>
                        {{ getNomeDisciplina(item["id"]) }}
                      </li>
                      <p class="ml-5">
                        {{ JSON.parse(aulaDuplicada.conteudo_polivalencia)[index] }}
                      </p>
                      <span class="ml-5">Horarios</span>
                      <ul class="ml-5" v-for="(horario, ind) in item.array" :key="ind">
                        <span>{{ getObjHorario(horario) }}</span>
                        <span></span>
                      </ul>
                      <hr />
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Metodologia</e-label>
                    <p class="font-weight-regular">
                      {{ aulaDuplicada.metodologia }}
                    </p>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    style="color: #fff"
                    class="red darken-1"
                    @click="$emit('dialogChange', false)"
                  >
                    Fechar
                  </v-btn>
                  <v-btn
                    :disabled="desabilitarSalvamento"
                    style="color: #fff"
                    class="green darken-1"
                    type="submit"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <e-modal-confirm
      :confirmDialog="confirmDialog"
      :objectModal="objectModal"
      @changeModalConfirm="enableSubmit"
      @changeActionResponse="duplicarAula"
    >
    </e-modal-confirm>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    submittingForm: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      require: true,
    },
    gestaoDeAulas: {
      type: Object,
      require: true,
    },
    horarios: {
      type: Array,
      require: true,
    },
    disciplinas: {
      type: Array,
    },
    allowedDates: {
      type: Function,
    },
    tipoAula: {
      type: String,
      require: false,
    },
  },
  mounted() {
    this.horarios_selecionados_extras = JSON.parse(this.aulaDuplicada.horarios_extras);
  },
  data() {
    return {
      array_horarios_extras: [],
      editarHorario: false,
      horarios_selecionados_extras: [],
      messageAlert: null,
      desabilitarSalvamento: false,
      date: null,
      data: null,
      modal: false,
      menu: false,
      aulaDuplicada: {},
      confirmDialog: false,
      dataAulaDuplicada: null,
      objectModal: {
        title: "Duplicar aula - Polivalência",
        message: "Tem certeza que deseja duplicar esta aula?",
      },
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
    };
  },
  watch: {
    data(val) {
      const count = 0;
      JSON.parse(this.aulaDuplicada.horarios_extras).forEach((element) => {
        element.array.forEach((horario) => {
          this.pesquisaAulas(horario, element.id, val);
        });
      });
    },
    form: {
      immediate: true,
      handler(val) {
        this.aulaDuplicada = val;
      },
    },
    dataAulaDuplicada(value) {
      const date = new Date(value);
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(date);
      this.data = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    valoresComuns(array1, array2) {
      return array1.filter((valor) => array2.includes(valor));
    },
    verifyHorariosSelectExtra(item, IDdisciplina, $event) {
      if (this.array_horarios_extras.filter((it) => it.id === IDdisciplina)[0]) {
        this.array_horarios_extras.filter((it) => it.id === IDdisciplina)[0].array = [];
      }

      const disciplina = {
        id: IDdisciplina,
        array: [],
      };

      const filter = this.array_horarios_extras.filter((it) => it.id === IDdisciplina);
      if (filter.length < 1) {
        this.array_horarios_extras.push(disciplina);
      }

      const horariosDosMeusCampos = [];

      item.forEach((element) => {
        this.array_horarios_extras.filter((it) => it.id === IDdisciplina)[0].array.push(element.id);

        this.pesquisaAulas(element.id, IDdisciplina);

        const teste = this.array_horarios_extras.filter((it) => it.id !== IDdisciplina);
        const horariosDosOutrosCampos = [];
        if (teste) {
          teste.forEach((it) => {
            it.array.forEach((element) => {
              horariosDosOutrosCampos.push(element);
            });
          });
        }

        horariosDosMeusCampos.push(element.id);

        const conflitouLocal = this.valoresComuns(horariosDosOutrosCampos, horariosDosMeusCampos);

        if (conflitouLocal.length > 0) {
          this.$toast.warn("Esse horário está sendo usado, escolha outro!");
          // remover o horário selecionado
          this.horarios_selecionados_extras[IDdisciplina] = this.horarios_selecionados_extras[
            IDdisciplina
          ].filter((it) => it.id !== element.id);
          // remover do array que envia
        }
      });
    },
    getObjHorario(id) {
      if (id) {
        const dis = this.horarios.filter((obj) => obj.id === id);
        return dis[0].descricao;
      }
    },
    getNomeDisciplina(id) {
      const dis = this.gestaoDeAulas.disciplinas_com_descricao.filter((obj) => obj.id === id);
      return dis[0].descricao;
    },
    async pesquisaAulas(horarioId, disciplinaId, data) {
      const data_aula = this.data.split("/").reverse().join("-");
      // /* eslint-disable */
      const { aula_id } = this.$route.params;
      const turma_id = this.aulaDuplicada.turma_id;
      if (!data_aula || !horarioId || !this.aulaDuplicada) return;

      const response = await this.$services.aulasService.pesquisarAulasEmConflitosPoli(
        data_aula,
        horarioId,
        turma_id,
        0
      );

      this.messageAlert = null;
      this.desabilitarSalvamento = false;

      if (response.data === 1) {
        this.aulasEmConflitos = true;
        this.messageAlert =
          "Já existe aula registrada nessa data com esses mesmos horários, selecione uma outra data!";
        this.desabilitarSalvamento = true;
      }
    },
    enableSubmit() {
      if (
        (this.data && this.horarios_selecionados_extras) ||
        this.aulaDuplicada.tipo_de_aula === "Aula Remota"
      ) {
        this.confirmDialog = !this.confirmDialog;
        this.aulaDuplicada.data = this.dataAulaDuplicada;
      } else {
        this.$toast.danger("Preencha todos os campos");
      }
    },
    async duplicarAula() {
      this.$loaderService.open("Duplicando a Aula");
      try {
        this.aulaDuplicada.horario_inicial = this.horarioSelecionado
          ? this.horarioSelecionado.inicio
          : null;
        this.aulaDuplicada.horario_final = this.horarioSelecionado
          ? this.horarioSelecionado.final
          : null;
        this.aulaDuplicada.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;
        this.aulaDuplicada.gestao_de_aula = parseInt(this.$route.params.gestaoDeAulaId, 10);
        this.aulaDuplicada.disciplina_id = this.gestaoDeAulas.disciplina_id;
        this.aulaDuplicada.turma_id = this.gestaoDeAulas.turma_id;
        this.aulaDuplicada.instrutor_id = this.gestaoDeAulas.instrutor_id;
        // so seguir se tiver horario disponivek e data

        const response =
          this.tipoAula === "complementar"
            ? await this.$services.aulasComplementaresService.criar(this.aulaDuplicada)
            : await this.$services.aulasService.criar(this.aulaDuplicada);
        this.confirmDialog = !this.confirmDialog;
        this.$emit("dialogChange", false);
        this.$toast.success("Aula duplicada com sucesso!");
        this.$router.push({ name: "aulas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>
